



@media (max-width:1300px) {
    .IfremeGestionEventos{
        scale: 1;
        width: 100%;
    }
}
@media (max-width:750px) {
    .IfremeGestionEventos{
        scale: 1.5;
        width:300px;
        // border-radius: 50%;
    }
    .switch-button{
        scale: 0.6;
    }
    .heder{
        scale:0.8;
        border-radius: 50%;
    }
    
}
@media (max-width:490px) {
    .IfremeGestionEventos{
        scale: 1;
        width:80%;
        margin: 0 auto;
        transform: translateX(00px);
        // border-radius: 50%;
    }
    .btn .btn-primary{
        scale: 0.8;
    } 
    .asd{
        border-radius: 500px;
        min-width: 15px !important;
    }
    .Respo{
        display: none;
    }
}
@media (max-width:356px) {
    .IfremeGestionEventos{
        scale: 1;
        width:100%;
        // margin: 0 auto;
        transform: translateX(-0px);
        // border-radius: 50%;
    }
}
// .TapaLogo{
//     width: 450px;
//     height: 50px;
//     background-color: #e9e9f1;
//     z-index: 800;
//     position: absolute;
//     transform: translateX(50px);
// }