.wrapper_carousel_home {
  margin: 3.5rem 0 12rem;
  position: relative;
  .box_item_datainfo {
    padding: 2rem;
    border-radius: 20px;
    &.bg {
      background: #e9e9f1;
    }
    & > img {
      max-height: 80px;
    }
  }
  .BrainhubCarousel__arrows {
    position: absolute;
    z-index: 1;
    bottom: -100px;
    padding: 25px;
    border-radius: 6px;
    background: #ffffff;
    border: 2px solid #333333;
    span {
      border-color: #333333;
    }
    &:hover {
      border: 2px solid var(--primary_color);

      background-color: var(--primary_color);
    }
  }
  .BrainhubCarousel__arrowLeft {
    left: 45%;
  }
  .BrainhubCarousel__arrowRight {
    right: 45%;
  }
}
