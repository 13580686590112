.section_tabs {
  margin: 3rem 0 0;
  .nav-tabs {
    gap: 15px;
    .nav-link {
      cursor: pointer;
      padding: 1rem;
      border-radius: 10px;
      min-height: 185px;
      height: 100%;
      position: relative;
      color: var(--color_black);
      border: 2px solid var(--color_black);
      &:hover,
      &.active {
        background: var(--color_morado);
        color: #ffffff;
        border: 2px solid var(--color_morado);
        &::after {
          background: #ffffff;
        }
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 25px;
        left: 1rem;
        height: 2px;
        width: 35px;
        background: var(--color_black);
      }
      .item_tabs_group {
        max-width: 125px;
        z-index: 1;
        overflow: hidden;
        position: relative;
        .span_ico_tabs {
          font-size: 1.25rem;
        }
        h6 {
          font-weight: 400;
          margin-top: 1.5rem;
        }
      }
      
    }
  }
  .tab-pane {
    margin-top: 5rem;
    .content_tabs_group {
      .box_info_tabs {
        margin-top: 2rem;
      }
      .box_subinfo_tabs {
        margin-top: 5rem;
        .ttlsb {
          position: relative;
          &::after {
            content: "";
            height: 3px;
            background: #333;
            max-width: 50px;
            width: 100%;
            position: absolute;
            top: -1rem;
            left: 0;
          }
          &:empty {
            &::after {
              display: none;
            }
          }
        }
      }
      .box_infoimgs_tabs {
        text-align: center;
        img {
          max-height: 500px;
          border-radius: 20px;
        }
      }
    }
  }
}

.IfremeGestionEventos{
  border: 0;
  width: 50%;
  height: 150px;
  scale: 1.5;
  margin-top: 100px;
}