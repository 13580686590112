:root {
  --primary_color: #39acc2;
  --color_morado: #74337f;
  --color_marin: #1c1e46;
  --color_black: #333333;
  --color_bg_grey: #e9e9f1;
  // --color_bg_grey: #e2e2ed;
}

@mixin bgDegradate {
  background: rgb(28, 30, 70);
  background: linear-gradient(
    151deg,
    rgba(28, 30, 70, 1) 0%,
    rgba(34, 35, 71, 1) 57%,
    rgba(55, 54, 119, 1) 100%
  );
}

@mixin borderCustom {
  border: 4px solid #ffffff;
  box-shadow: 2px 2px 22px rgba(0, 0, 0, 0.25);
}

html {
  scroll-behavior: auto !important;
}

body {
  font-family: "Roboto", sans-serif !important;
  font-size: 0.95rem;
}

.contain__section {
  max-width: 760px;
  margin: 0 auto;
}

a {
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.trans {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.c_primary {
  color: var(--primary_color);
}

.c_morado {
  color: var(--color_morado);
}

.c_muted {
  color: #999999;
}

.c_marin {
  color: var(--color_marin);
}

.container_am {
  max-width: 860px;
  margin: 0 auto;
  padding: 0 1rem;
}

.pdr_2{
  padding-right: 2rem;
}

.btn {
  font-weight: 500;
  height: 45px;
  min-width: 150px;
  border-radius: 0.6rem !important;
  font-size: 0.9rem;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  span {
    padding: 0 0.15rem;
  }
  &.btn-primary {
    border: 0;
    background: rgb(51, 180, 204);
    background: linear-gradient(
      79deg,
      rgba(51, 180, 204, 1) 10%,
      rgba(123, 52, 131, 1) 100%
    );
    color: #ffffff;
    padding-top: 0.7rem;
  }
  &.btn-brochure {
    border: 1.5px solid #ffffff;
    color: #ffffff;
    &:hover {
      border: 1.5px solid #ffffff;
      color: #333333;
      background: #ffffff;
    }
    .fa-solid {
      font-size: 1.1rem;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      max-width: 95px;
      text-align: left;
      line-height: 1.15;
      font-size: 0.8rem;
      padding-left: 0.5rem;
    }
    &.in-color {
      border: 1.5px solid var(--color_black);
      color: var(--color_black);
      &:hover {
        border: 1.5px solid var(--color_black);
        color: #333333;
        background: #ffffff;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        max-width: 95px;
        text-align: left;
        line-height: 1.15;
        font-size: 0.8rem;
        padding-left: 0.5rem;
      }
    }
  }
}

.ttls {
  line-height: 1.1;
  &.ttl_in_home {
    font-size: 65px;
  }
  &.ttl_thin {
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 4px;
    font-size: 15px;
    span {
      padding: 0 1rem;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 100%;
        top: 50%;
        height: 1px;
        width: 35px;
        background: #333333;
        transform: translateY(-50%);
      }
      &::after {
        content: "";
        position: absolute;
        right: 100%;
        top: 50%;
        height: 1px;
        width: 35px;
        background: #333333;
        transform: translateY(-50%);
      }
    }
    &.simple {
      span {
        padding-left: 0;
        &::after {
          display: none;
        }
      }
    }
  }
  &.ttl_section {
    font-weight: 700;
    font-size: 43px;
  }
  &.ttl_section_med {
    font-weight: 700;
    font-size: 48px;
    line-height: 1;
  }
}

.paragraph {
  line-height: 1.3;
  font-weight: 300;
  &.sm {
    font-size: 18px;
  }
  &.lg {
    font-size: 20px;
  }
}

.cite {
  background: #f4f4f4;
  font-weight: 500;
  font-style: italic;
  padding: 1rem;
  border-left: 5px solid var(--primary_color);
}

.fw_300 {
  font-weight: 300;
}
.fw_400 {
  font-weight: 400;
}
.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}
.fw_700 {
  font-weight: 700;
}
.fw_900 {
  font-weight: 900;
}

.video-ply-btn {
  max-width: 430px;
  padding-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;
  .popup-video {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    min-width: 100px;
    height: 100px;
    line-height: 100px;
    position: relative;
    z-index: 9;
    border-radius: 50%;
    border: 0;
    text-align: center;
    font-size: 50px;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    background-color: var(--primary_color);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(72, 88, 238, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(72, 88, 238, 0.2);
    .fa-play {
      font-size: 2.5rem;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: var(--color_morado);
    }
    &:after {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100px;
      width: 100px;
      border: 3px solid #ffffff;
      border-radius: 50%;
      -webkit-animation: icon-bubble 1s infinite forwards linear;
      animation: icon-bubble 1s infinite forwards linear;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100px;
      width: 100px;
      border: 3px solid #ffffff;
      border-radius: 50%;
      -webkit-animation: icon-bubble 2s infinite forwards linear 1s;
      animation: icon-bubble 2s infinite forwards linear 1s;
    }
  }
  .paragraph {
    max-width: 300px;
    font-weight: 300;
  }
}

video,
img {
  max-width: 100%;
}

.wrapper_modal_home {
  max-width: 100%;
  width: 100%;
  padding: 1rem;
  .sec_video_pride {
    .btnclose {
      font-size: 2rem;
      color: var(--color_morado);
      position: absolute;
      top: 0;
      right: 10px;
      z-index: 1;
      text-shadow: 2px 2px 12px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
    video{
      width: 100% !important;
    }
  }

  .modal-content{
    height: auto !important;
    width: 100% !important;
    max-width: 1200px;
    margin: 0 auto;
    background: transparent;
  }
}
  .modal.show{
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

@-webkit-keyframes icon-bubble {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.8;
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.55;
  }
  75% {
    -webkit-transform: scale(1.32);
    transform: scale(1.32);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
  }
}

@keyframes icon-bubble {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.8;
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.55;
  }
  75% {
    -webkit-transform: scale(1.32);
    transform: scale(1.32);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
  }
}

.box_options_btns {
  display: flex;
  align-items: center;
  gap: 8px;
}

.drawer_mnsj {
  .mnsj_info {
    max-width: 450px;
  }
  .box_options_btns {
    justify-content: flex-end;
  }
}

/* == Slider  == */
.slick-slider {
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.loader{
  position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: #0000008f;
    z-index: 9;
}
.loader img{
  max-width: 250px;
}
@media (max-width:490px) {

  .btn-brochure, .btn-primary{
      scale: 0.8;
  } .customResposive{
    scale: 0.8;
  }
}