.wrapper_header {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  .header_top {
    .sec_top {
      font-size: 0.8rem;
      padding: 0.4rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        color: #fff;
        &:hover {
          color: var(--primary_color);
        }
      }
      .box_info_business {
        display: flex;
        color: #ffffff;
        gap: 5px;
      }
      .box_social_networks {
        color: #fff;
        display: flex;
        align-items: center;
        gap: 4px;
        i {
          font-size: 1rem;
        }
      }
    }
  }
  .header_botton {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0.5rem 0 0.65rem;
    .sec_brand {
      img {
        max-height: 55px;
      }
      min-width: 70px;
    }
    .sec_options {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .nav_options_item {
        width: 100%;
        font-size: 0.9rem;
        &.open__menu{
          display: block;
        }
        ul {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          gap: 20px;
          li {
            list-style: none;
            .item_link {
              color: #ffffff;
              text-decoration: none;
              font-weight: 500;
              cursor: pointer;
            }
            .item_link.link_dropdown {
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }
        }
        .dwld_box{
          text-align: center;
          display: none;
        }
      }
      .box_options_btns{
        .btn_menu{
          display: none;
          color: #ffffff;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
          border-radius: 5px;
          cursor: pointer;
          &:hover{
            background: var(--primary_color);
          }
        }
      }
    }
  }
  &.header_change {
    background: #ffffff;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
    .box_info_business,
    .box_social_networks,
    .header_botton .sec_options .nav_options_item ul li .item_link {
      color: var(--color_black);
    }
    .btn.btn-brochure {
      border: 1.5px solid var(--color_black);
      color: var(--color_black);
    }
    .btn.btn-primary {
      color: #fff;
    }
    .header_top {
      background: var(--color_marin);
    }
    .btn_menu{
      color: #333333 !important;
    }
  }
  .header_subnav {
    background: #ffffff;
    padding: 1rem;
    padding-bottom: 2rem;
    border-top: 2px solid #efefef;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.15);
    .content_subheader {
      position: relative;
      .box_go_back {
        display: none;
        padding: 0.35rem 0.5rem;
        border-radius: 0.3rem;
        font-size: 0.8rem;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        &:hover {
          background: #dedede;
        }
      }
      .row_subheader {
        display: flex;
        justify-content: space-between;
        gap: 25px;
        .sec_info_subheader {
          max-width: 250px;
          margin-top: 1rem;
        }
        .sec_nav_subheader {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 25px;
          .box_item_link_service {
            width: calc(33% - 30px);
            color: #333333;
            padding: 1rem;
            border-radius: 10px;
            &:hover {
              background: #e9e9ef;
              .name_link_head {
                color: var(--primary_color);
              }
              .paragraph {
                color: var(--color_marin);
              }
            }
            .name_link_head {
              text-transform: uppercase;
              letter-spacing: 1px;
              min-height: 40px;
            }
            .paragraph {
              color: #999999;
            }
            ul {
              padding: 0;
              margin: 0;
              li {
                list-style: none;
              }
            }
            .fa-solid {
              font-size: 1.15rem;
            }
          }
        }
      }
    }
  }

  .nav_options_item {
    .dropdown-menu.show {
      position: fixed !important;
      top: 103px !important;
      left: 0;
      transform: none !important;
      width: 100%;
      border-radius: 0;
      padding: 0;
      border: 0 !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper_header {
    .nav_options_item {
      .dropdown-menu.show {
        top: 0 !important;
        height: 100%;
        .header_subnav {
          padding: 1rem 0.5rem;
          height: 100%;
          overflow: auto;
          .name_link_head {
            color: var(--primary_color);
          }
          .content_subheader .box_go_back {
            display: block;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }
}
:root {
  --color-button: #fdffff;
}

.switch-button {
  display: inline-block;
}


.switch-button .switch-button__checkbox {
  display: none;
}

.switch-button .switch-button__label {
  background-color: #4579fa; 
  background-image: url(https://cdn-icons-png.flaticon.com/512/4628/4628635.png);
  width: 5rem;
  height: 3rem;
  border-radius: 3rem;
  display: inline-block;
  position: relative;
}

.switch-button .switch-button__label:before {
  transition: .2s;
  display: block;
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-image: url(https://cdn-icons-png.flaticon.com/512/4628/4628635.png);
  content: '';
  background-size: contain;
  background-position: center;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px var(--color-black);
  background-color: #ffffff;
}

.switch-button .switch-button__checkbox:checked + .switch-button__label {
  background-color: #4579fa; 
  background-image: url(https://cdn-icons-png.flaticon.com/512/10602/10602501.png);
}

.switch-button .switch-button__checkbox:checked + .switch-button__label:before {
  transform: translateX(2rem);
}


.switch-button .switch-button__labelEN {
  background-color: #0fab55;
  background-image: url(https://cdn-icons-png.flaticon.com/512/10602/10602501.png);
  width: 5rem;
  height: 3rem;
  border-radius: 3rem;
  display: inline-block;
  position: relative;
}

.switch-button .switch-button__labelEN:before {
  transition: .2s;
  display: block;
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-image: url(https://cdn-icons-png.flaticon.com/512/10602/10602501.png);
  content: '';
  background-size: contain;
  background-position: center;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px var(--color-black);
  background-color: #ffffff;
}
.switch-button .switch-button__checkbox:checked + .switch-button__labelEN {
  background-color: #4579fa; 
  background-image: url(https://cdn-icons-png.flaticon.com/512/10602/10602501.png);
}

.switch-button .switch-button__checkbox:checked + .switch-button__labelEN:before {
  transform: translateX(2rem);
}

.textLen {
  font-weight: 700;
  position:absolute;
  z-index: 5000000000;
  color: #ffffff;
  transform: translate(10px,-42px);
}
.textLen-traslationES{
  transform: translate(53px,-42px);
}
.textLen-traslationEN{
  transform: translate(10px,-42px);
}